<template>
  <tui-image-editor
    :include-ui="useDefaultUI"
    :options="options"
  ></tui-image-editor>
</template>
<script>
import "tui-color-picker/dist/tui-color-picker.css";
import "tui-image-editor/dist/tui-image-editor.css";
import { ImageEditor } from "@toast-ui/vue-image-editor";

export default {
  components: {
    "tui-image-editor": ImageEditor
  },
  data() {
    return {
      useDefaultUI: true,
      options: {
        // for tui-image-editor component's "options" prop
        cssMaxWidth: 2200,
        cssMaxHeight: 2200,
        includeUI: {
          menuBarPosition: "left",
          usageStatistics: false,
          initMenu: "icon",
          menu: ["icon", "text"]
        }
      }
    };
  }
};
</script>
<style >
.tui-image-editor-container {
  height: 80vh !important;
}
.tui-image-editor-header-logo {
  display: none;
}
.tui-image-editor-help-menu {
  display: none !important;
}
</style>